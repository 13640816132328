<template>
  <div>
    <b-overlay :show="loading">
      <div>
        <b-card>
          <h4>แคมเปญ : {{ cDisplayTitle }}</h4>
          <p><b>หมายเหตุ :</b> {{ cDisplayDetail }}</p>
          <p class="mb-50"><b>วันที่เปิดใช้งาน :</b> {{ cMainStartDate }}</p>
          <p class="mb-50"><b>วันหมดอายุ :</b> {{ cMainEndDate }}</p>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            size="sm"
            @click="clickEditMainData"
          >
            แก้ไขรายละเอียดทั่วไป
          </b-button>

          <b-button
            v-if="mainData"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :variant="mainData.is_active ? 'danger' : 'success'"
            block
            size="sm"
            @click="toggleActiveCode"
          >
            {{ cBtnToggleActiveCode }}
          </b-button>

          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            block
            size="sm"
            @click="forceDeleteRedeem"
          >
            บังคับลบแคมเปญนี้ (ให้สอบถามผู้ดูแลก่อนลบแบบนี้)
          </b-button> -->
          <hr />

          <b-row cols="1" no-gutters>
            <b-col>
              <p class="mb-50">จำนวน Code ที่ต้องการเพิ่ม</p>
              <b-form-input v-model="addOnAmount" :formatter="formatterAmount" number />
            </b-col>
            <b-col class="mt-1">
              <p class="mb-50">ราคาเครดิตต่อ Code</p>
              <b-form-input v-model="addOnPriceCreditPerCode" :formatter="formatterAmount" number />
            </b-col>
            <b-col class="mt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                block
                size="sm"
                @click="addMoreCode"
              >
                เพิ่มโค้ด
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </b-overlay>

    <table-local-v-1-widget
      :items="listCode"
      :columns="columns"
      :actions="actions"
      title="ประวัติการใช้ Redeem"
      :loading="tableLoading"
    >
      <template #cardHeader>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          class="mb-1"
          size="sm"
          @click="copyAllCodeIsNotUse"
        >
          คัดลอก Code ที่ยังไม่ถูกใช้งานทั้งหมด
        </b-button>
      </template>
      <template #user_id="{row}">
        <div>
          <b-badge :variant="row.user_id ? 'success' : 'secondary'">
            {{ row.user_id ? 'ใช้งานแล้ว' : 'ยังไม่ถูกใช้งาน' }}
          </b-badge>

          <div v-if="row.user_id">
            <b-link :to="{ name: 'userManagement-user-info', params: { userId: row.user_id } }">
              ใช้โดย {{ row.user_id }}
            </b-link>
          </div>
        </div>
      </template>

      <template #customAction="{row}">
        <div>
          <b-button
            v-if="!row.user_id"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="clickDeleteCodeRedeem(row)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </div>
      </template>
    </table-local-v-1-widget>

    <b-modal
      ref="refModalFormCreateCampaignRedeemCoin"
      title="แก้ไขรายะเอียดทั่วไป"
      size="md"
      hide-footer
      centered
      modal-class="modal-primary"
    >
      <FormCreateCampaignRedeemCoin ref="refFormCreateCampaignRedeemCoin" @onSubmit="onSubmitFormEdit" />
    </b-modal>

    <!-- <pre>{{ JSON.stringify(mainData, null, 2) }}</pre> -->
  </div>
</template>

<script>
import FormCreateCampaignRedeemCoin from './components/FormCreateCampaignRedeemCoin.vue'

export default {
  components: {
    FormCreateCampaignRedeemCoin,
  },
  data() {
    return {
      loading: false,
      mainData: null,
      columns: [
        {
          label: 'Code',
          field: 'code',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ราคาต่อ Code',
          field: 'credit',
          tdClass: 'text-center',
          thClass: 'text-center',
          type: 'currency',
        },
        {
          label: 'สถานะ',
          field: 'user_id',
          tdClass: 'text-center',
          thClass: 'text-center',
          type: 'custom',
        },

        {
          label: '',
          field: 'customAction',
          tdClass: 'text-center',
          thClass: 'text-center',
          type: 'custom',
          sortable: false,
        },
      ],
      actions: [],
      addOnAmount: null,
      addOnPriceCreditPerCode: null,
      listCode: [],
      tableLoading: false,
    }
  },
  computed: {
    cColumns() {
      // if (this.mainData?.limited_use) {
      //   return [
      //     ...this.columns.slice(0, 2),
      //     {
      //       label: 'ใช้งานโดย',
      //       field: 'use_by',
      //       formatFn: val => val?.email || '-',
      //       tdClass: 'text-center',
      //       thClass: 'text-center',
      //     },
      //     this.columns[2],
      //   ]
      // }

      return [...this.columns]
    },
    cDisplayTitle() {
      return this.mainData?.name
    },
    cDisplayDetail() {
      return this.mainData?.detail
    },
    cRedeemCodeList() {
      return this.mainData?.redeeming_code_datas || []
    },
    cDisplayNameMainImage() {
      return this.mainData?.topic_image_data?.name
    },
    cMainStartDate() {
      if (this.mainData?.start_date) {
        return this.$date(this.mainData.start_date)
          .locale('th')
          .format('DD MMM YYYY HH:mm')
      }

      return ''
    },
    cMainEndDate() {
      if (this.mainData?.end_date) {
        return this.$date(this.mainData.end_date)
          .locale('th')
          .format('DD MMM YYYY HH:mm')
      }

      return 'ไม่มีวันหมดอายุ'
    },
    cBtnToggleActiveCode() {
      return this.mainData?.is_active ? 'ปิดการใช้งาน Redeem ทั้งหมด' : 'เปิดการใช้งาน Redeem ทั้งหมด'
    },
    cDisplayHideInStore() {
      if (!this.mainData?.topic_image_data) return ''

      return this.mainData?.topic_image_data.hide_in_store
        ? 'ไม่แสดงในแอปและเว็บ (สามารถใช้ Redeem เพื่อสร้างรูปวอลเปเปอร์ได้เท่านั้น)'
        : 'แสดงในแอปและเว็บ (สามารถใช้ Redeem หรือ Coin เพื่อสร้างรูปวอลเปเปอร์ได้)'
    },
    cDisplayLimitUse() {
      if (!this.mainData) return ''
      if ('limited_use' in this.mainData) {
        return this.mainData.limited_use ? 'ใช่' : 'ไม่'
      }

      return 'ไม่พบข้อมูล'
    },
  },
  mounted() {
    const { redeemMainId } = this.$route.params

    if (redeemMainId) {
      this.fetchMainDataRedeemById(redeemMainId)
      this.fetchTableDataRedeemById(redeemMainId)
    } else {
      this.gDisplayToast('ไม่พบแคมเปญ', 'ดูเหมือนแคมเปญนี้จะถูกลบไปแล้ว', 'danger')
      this.$router.replace({ name: 'redeem-coin-management' })
    }
  },
  methods: {
    async fetchMainDataRedeemById(id = '') {
      if (!id) return
      this.loading = true
      const resp = await this.api.getV2(`api/admin/redeeming-pocket-money/${id}`).catch(() => null)
      //   console.log('fetchMainDataRedeemById', resp)

      if (resp && resp.code === 200) {
        this.mainData = { ...resp.data }
      } else {
        this.gDisplayToast('ไม่พบแคมเปญ', 'ดูเหมือนแคมเปญนี้จะถูกลบไปแล้ว', 'danger')
        this.$router.replace({ name: 'redeem-coin-management' })
      }
      this.loading = false
    },
    async fetchTableDataRedeemById(id = '') {
      if (!id) return
      this.tableLoading = true
      const resp = await this.api.getV2(`api/admin/redeeming-pocket-money/index/${id}`).catch(() => null)
      //   console.log('fetchMainDataRedeemById', resp)

      if (resp && resp.code === 200) {
        this.listCode = [...resp.data]
      }
      this.tableLoading = false
    },
    async clickDeleteCodeRedeem(values) {
      const resp = await this.api.deleteV2(`api/admin/redeeming-code-data/${values.id}`, this, true)
      this.fetchTableDataRedeemById(this.$route.params.redeemMainId)

      if (!resp) return

      if (resp.message) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
        return
      }
      this.gDisplayToast('ลบ Code สำเร็จแล้ว')
    },
    async addMoreCode() {
      const { addOnAmount, addOnPriceCreditPerCode } = this

      if (!addOnAmount || addOnAmount === 0) {
        this.gDisplayToast('เพิ่มโค้ดไม่สำเร็จ', 'โปรดระบุจำนวน Code ที่ต้องการเพิ่ม และค่าต้องมากกว่า 0', 'danger')
        return
      }

      if (!addOnPriceCreditPerCode || addOnPriceCreditPerCode === 0) {
        this.gDisplayToast('เพิ่มโค้ดไม่สำเร็จ', 'โปรดระบุราคาเครดิตต่อ Code และค่าต้องมากกว่า 0', 'danger')
        return
      }

      const isConfirm = await this.gCheckConfirmV1(
        'ยืนยันเพิ่ม Code',
        `ต้องการเพิ่ม Code ราคา ${this.formatNumberToCurrency(
          addOnPriceCreditPerCode,
        )} (เครดิตต่อ Code) จำนวน ${this.formatNumberToCurrency(addOnAmount)} (Code) ใช่หรือไม่`,
      )

      if (!isConfirm) return

      this.gOpenPageLoading()
      const resp = await this.api.postV2('api/admin/redeeming-pocket-money-code', {
        amount: addOnAmount,
        credit: addOnPriceCreditPerCode,
        redeeming_pocket_money_data_id: this.mainData.id,
      })
      this.gClosePageLoading()

      if (resp && resp.code === 200) {
        this.gDisplayToast('เพิ่ม Code สำเร็จแล้ว', '', 'success')
        this.fetchTableDataRedeemById(this.$route.params.redeemMainId)
      } else {
        this.gDisplayToast('เพิ่ม Code ไม่สำเร็จ', resp?.data?.message, 'danger')
      }
    },
    formatterAmount(value) {
      const n = Number(value)
      if (Number.isNaN(n)) return value
      return Math.round(n)
    },
    clickEditMainData() {
      this.$refs.refModalFormCreateCampaignRedeemCoin.show()

      setTimeout(() => {
        // eslint-disable-next-line no-unused-expressions
        this.$refs.refFormCreateCampaignRedeemCoin?.updateForm({
          ...this.mainData,
        })
      }, 250)
    },
    async onSubmitFormEdit(values) {
      const checkConfirm = await this.gCheckConfirmV1('ยืนยันแก้ไขรายละเอียดทั่วไป')
      if (!checkConfirm) return
      this.$refs.refModalFormCreateCampaignRedeemCoin.hide()

      this.loading = true
      const resp = await this.api.putV2(`api/admin/redeeming-pocket-money/${values.id}`, values, this)
      this.loading = false
      if (!resp) return

      if (resp && resp.code === 200) {
        this.gDisplayToast('แก้ไขรายละเอียดทั่วไปสำเร็จ')
        this.fetchMainDataRedeemById(values.id)
      } else {
        this.gDisplayToast('แก้ไขรายละเอียดทั่วไปไม่สำเร็จ', resp?.data?.message, 'danger')
      }
    },
    async toggleActiveCode() {
      const title = this.mainData.is_active ? 'ยืนยันปิดการใช้งาน' : 'ยืนยันเปิดการใช้งาน'

      const checkConfirm = await this.gCheckConfirmV1(title)
      if (!checkConfirm) return
      this.loading = true
      const resp = await this.api
        .getV2(`api/admin/redeeming-topic-data/toggle-active/${this.mainData.id}`)
        .catch(() => null)
      this.loading = false
      if (resp && resp.code === 200) {
        this.gDisplayToast(`${title} สำเร็จแล้ว`)
        this.mainData.is_active = !this.mainData.is_active
      } else {
        this.gDisplayToast('พบข้อผิดพลาด', resp?.data?.message, 'danger')
      }
    },
    async forceDeleteRedeem() {
      const resp = await this.api.deleteV2(
        `api/admin/redeeming-topic-data/confirm-delete/${this.mainData.id}`,
        this,
        true,
        {
          title: 'ยืนยันบังคับลบแคมเปญนี้',
          body: 'ระบบจะทำการลบแคมเปญนี้ โดยที่ไม่สนใจ Code ที่มีการถูกใช้ไปแล้ว โปรดตรวจสอบให้แน่ใจก่อนกดยืนยัน',
        },
      )
      if (!resp) return

      if (resp === 'ลบข้อมูลสำเร็จ') {
        this.gDisplayToast('ลบแคมเปญนี้สำเร็จแล้ว')

        this.$router.replace({ name: 'redeem-management' })
        return
      }

      if (typeof resp === 'object' && resp.message) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
      }
    },
    async copyAllCodeIsNotUse() {
      const allRedeemList = [...this.mainData.redeeming_code_datas]

      const filterIsNotUse = allRedeemList
        .filter(v => !v.user_id)
        .map(v => `${v.code} ราคา ${this.formatNumberToCurrency(v.credit)} เครดิต`)

      if (filterIsNotUse.length <= 0) {
        this.gDisplayToast('คัดลอกไม่สำเร็จ', 'เนื่องจาก Code ทั้งหมดถูกใช้งานไปแล้ว', 'danger')
        return
      }

      const filterToString = filterIsNotUse.join('\n')
      const textTemplateCopy = `แคมเปญ : ${this.cDisplayTitle} \nจำนวนทั้งหมด ${filterIsNotUse.length} Code\nวันที่เริ่มใช้งานได้ : ${this.cMainStartDate}\nวันที่หมดอายุ : ${this.cMainEndDate} \n${filterToString}`
      // console.log('textTemplateCopy', textTemplateCopy)

      const resp = await this.$copyText(textTemplateCopy).catch(() => null)

      if (resp) {
        this.gDisplayToast('คัดลอกสำเร็จแล้ว')
      } else {
        this.gDisplayToast('คัดลอกไม่สำเร็จ', 'โปรดติดต่อผู้ดูแลระบบ', 'danger')
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
