<template>
  <div>
    <validation-observer ref="refFormCreateCampaignRedeemCoin">
      <b-form>
        <b-row>
          <b-col sm="12">
            <b-form-group label="ชื่อแคมเปญ">
              <validation-provider #default="{ errors }" name="redeem_group_title" rules="required">
                <b-form-input v-model="form.name" :state="errors.length > 0 ? false : null" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="12">
            <b-form-group label="หมายเหตุ">
              <validation-provider #default="{ errors }" name="redeem_group_detail" rules="">
                <b-form-textarea v-model="form.detail" :state="errors.length > 0 ? false : null" rows="3" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="12">
            <b-overlay :show="!cCanEditStartDate">
              <template v-slot:overlay>
                <div class="d-flex align-items-center flex-column">
                  <small class="m-0 text-danger">ไม่สามารถแก้ไขได้เนื่องจากเลยวันที่เปิดใช้งานแล้ว</small>
                </div>
              </template>
              <b-form-group label="วันที่เปิดใช้งาน">
                <validation-provider #default="{ errors }" name="redeem_group_start_date" rules="">
                  <b-form-checkbox v-model="cStartDate" name="check-button" switch inline>
                    เปิดตั้งค่า (ถ้าไม่เปิดจะใช้เป็นเวลาปัจจุบัน)
                  </b-form-checkbox>
                  <flat-pickr
                    v-if="cStartDate"
                    v-model="form.start_date"
                    :config="configDate"
                    class="form-control text-center mt-1"
                    placeholder="วัน/เดือน/ปี (ค.ศ.) ชั่วโมง:นาที:วินาที"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-overlay>
          </b-col>

          <b-col sm="12">
            <b-form-group label="วันหมดอายุ">
              <validation-provider #default="{ errors }" name="redeem_group_end_date" rules="">
                <b-form-checkbox v-model="cEndDate" name="check-button" switch inline>
                  เปิดตั้งค่า (ถ้าไม่เปิดจะเป็นไม่มีวันหมดอายุ)
                </b-form-checkbox>
                <flat-pickr
                  v-if="cEndDate"
                  v-model="form.end_date"
                  :config="cConfigEndDateInput"
                  class="form-control text-center mt-1"
                  placeholder="วัน/เดือน/ปี (ค.ศ.) ชั่วโมง:นาที:วินาที"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col sm="12">
            <b-button variant="primary" type="submit" block @click.prevent="submitForm">
              {{ cDisplayButtonSubmit }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import { Thai } from 'flatpickr/dist/l10n/th'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  data() {
    return {
      form: {
        name: '',
        detail: '',
        start_date: null,
        end_date: null,
      },
      configDate: {
        dateFormat: 'Z',
        altFormat: 'j/F/Y H:i',
        altInput: true,
        locale: Thai,
        minDate: new Date(),
        enableTime: true,
        time_24hr: true,
        minuteIncrement: 1,
      },
    }
  },
  computed: {
    cStartDate: {
      get() {
        return Boolean(this.form.start_date)
      },
      set(v) {
        if (v) {
          this.form.start_date = this.form.start_date || new Date()
        } else {
          this.form.start_date = null
        }
        return v
      },
    },
    cEndDate: {
      get() {
        return Boolean(this.form.end_date)
      },
      set(v) {
        if (v) {
          this.form.end_date = this.form.end_date || new Date()
        } else {
          this.form.end_date = null
        }
        return v
      },
    },
    cCanEditStartDate() {
      if (this.form?.id && this.$date().isAfter(this.form.start_date)) return false

      return true
    },
    cDisplayButtonSubmit() {
      return this.form?.id ? 'แก้ไข' : 'เพิ่ม'
    },
    cConfigEndDateInput() {
      return {
        dateFormat: 'Z',
        altFormat: 'j/F/Y H:i',
        altInput: true,
        locale: Thai,
        minDate: null,
        enableTime: true,
        time_24hr: true,
        minuteIncrement: 1,
      }
    },
  },
  methods: {
    async submitForm() {
      const validate = await this.$refs.refFormCreateCampaignRedeemCoin?.validate()
      if (!validate) return

      const startData = this.form.start_date
        ? this.$date(this.form.start_date).format('YYYY-MM-DD HH:mm')
        : this.$date().format('YYYY-MM-DD HH:mm')

      const endDate = this.form.end_date ? this.$date(this.form.end_date).format('YYYY-MM-DD HH:mm') : null

      if (endDate && this.$date(endDate)?.isBefore(startData)) {
        this.gDisplayToast('โปรดตรวจสอบข้อมูลอีกครั้ง', 'วันหมดอายุต้องมากกว่าวันที่เปิดใช้งาน', 'danger')
        return
      }

      const newForm = {
        ...this.form,
        start_date: startData,
        end_date: endDate,
      }

      this.$emit('onSubmit', newForm)
    },
    formatterAmount(value) {
      const n = Number(value)
      if (Number.isNaN(n)) return value
      return Math.round(n)
    },
    updateForm(values) {
      this.form = { ...values }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
