var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"refFormCreateCampaignRedeemCoin"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"ชื่อแคมเปญ"}},[_c('validation-provider',{attrs:{"name":"redeem_group_title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"หมายเหตุ"}},[_c('validation-provider',{attrs:{"name":"redeem_group_detail","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null,"rows":"3"},model:{value:(_vm.form.detail),callback:function ($$v) {_vm.$set(_vm.form, "detail", $$v)},expression:"form.detail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-overlay',{attrs:{"show":!_vm.cCanEditStartDate},scopedSlots:_vm._u([{key:"overlay",fn:function(){return [_c('div',{staticClass:"d-flex align-items-center flex-column"},[_c('small',{staticClass:"m-0 text-danger"},[_vm._v("ไม่สามารถแก้ไขได้เนื่องจากเลยวันที่เปิดใช้งานแล้ว")])])]},proxy:true}])},[_c('b-form-group',{attrs:{"label":"วันที่เปิดใช้งาน"}},[_c('validation-provider',{attrs:{"name":"redeem_group_start_date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.cStartDate),callback:function ($$v) {_vm.cStartDate=$$v},expression:"cStartDate"}},[_vm._v(" เปิดตั้งค่า (ถ้าไม่เปิดจะใช้เป็นเวลาปัจจุบัน) ")]),(_vm.cStartDate)?_c('flat-pickr',{staticClass:"form-control text-center mt-1",attrs:{"config":_vm.configDate,"placeholder":"วัน/เดือน/ปี (ค.ศ.) ชั่วโมง:นาที:วินาที"},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-form-group',{attrs:{"label":"วันหมดอายุ"}},[_c('validation-provider',{attrs:{"name":"redeem_group_end_date","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"name":"check-button","switch":"","inline":""},model:{value:(_vm.cEndDate),callback:function ($$v) {_vm.cEndDate=$$v},expression:"cEndDate"}},[_vm._v(" เปิดตั้งค่า (ถ้าไม่เปิดจะเป็นไม่มีวันหมดอายุ) ")]),(_vm.cEndDate)?_c('flat-pickr',{staticClass:"form-control text-center mt-1",attrs:{"config":_vm.cConfigEndDateInput,"placeholder":"วัน/เดือน/ปี (ค.ศ.) ชั่วโมง:นาที:วินาที"},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"sm":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit","block":""},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.cDisplayButtonSubmit)+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }